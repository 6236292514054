.contact-form-container {
  width: 75%;
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.back-button {
  cursor: pointer;
}
