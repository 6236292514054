// financial section
@media screen and (min-width: 768px) {
  .financial-freedom-text {
    width: 460px;
  }
  .financial-peace-text {
    width: 350px;
  }
  .financial-mission-text {
    width: 460px;
  }
  .financial-abundance-text {
    width: 350px;
  }
}

.financial-cloud-white {
  left: 10px;
  top: 215px;
  width: 316px;
  height: 82px;
  @media screen and (min-width: 768px) {
    left: 180px;
  }
}

.fi-fade-cloud-right-small {
  right: -12px;
  top: 445px;
  width: 636px;
  height: 217px;
}

.fi-fade-cloud-right-large {
  right: -42px;
  top: 720px;
  width: 764px;
  height: 256px;
}

.red-bird-left {
  left: 160px;
  top: 550px;
  opacity: 0.25;
  @media screen and (max-width: 767px) {
    width: 120px;
    height: 100px;
  }
  @media screen and (min-width: 768px) {
    width: 153px;
    height: 128px;
    left: 100px;
    top: 650px;
  }
  @media screen and (min-width: 1200px) {
    opacity: 1;
  }
}

.red-bird-right {
  right: 135px;
  top: 338px;
  opacity: 0.25;
  @media screen and (max-width: 767px) {
    width: 120px;
    height: 100px;
  }
  @media screen and (min-width: 768px) {
    width: 159px;
    height: 101px;
  }
  @media screen and (min-width: 1200px) {
    opacity: 1;
  }
}

.line-right {
  right: 265px;
  top: 450px;
  width: 187px;
  height: 117px;
}

.line-left {
  left: 88px;
  top: 720px;
  width: 312px;
  height: 117px;
}

.bird-1 {
  left: 125px;
  top: 835px;
  width: 27px;
  height: 36px;
}

.bird-2 {
  left: 210px;
  top: 885px;
  width: 27px;
  height: 29px;
}

.bird-3 {
  left: 175px;
  top: 790px;
  width: 48px;
  height: 54px;
}

.bird-4 {
  left: 205px;
  top: 725px;
  width: 32px;
  height: 34px;
}

.bird-5 {
  left: 305px;
  top: 760px;
  width: 40px;
  height: 53px;
}

.bird-6 {
  left: 340px;
  top: 790px;
  width: 72px;
  height: 69px;
}

.bird-7 {
  left: 532px;
  top: 500px;
  width: 43px;
  height: 44px;
}

.bird-8 {
  right: 430px;
  top: 480px;
  width: 72px;
  height: 68px;
}

.bird-9 {
  right: 265px;
  top: 530px;
  width: 44px;
  height: 43px;
}

.bird-10 {
  right: 340px;
  top: 600px;
  width: 66px;
  height: 55px;
}

.bird-11 {
  right: 275px;
  top: 485px;
  width: 60px;
  height: 33px;
}

.bird-12 {
  right: 364px;
  top: 575px;
  width: 40px;
  height: 48px;
}

.bird-13 {
  right: 245px;
  top: 660px;
  width: 80px;
  height: 78px;
}

.bird-14 {
  right: 370px;
  top: 410px;
  width: 44px;
  height: 78px;
}

.bird-15 {
  right: 265px;
  top: 755px;
  width: 41px;
  height: 33px;
}

.bird-16 {
  right: 135px;
  top: 600px;
  width: 93px;
  height: 92px;
}
