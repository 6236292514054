
.item-container {
  margin-top: 15px;
  gap: 8px;
  background-color: $gray-100;
}

.item {
  background-color:$white;
  padding: 8px;
  border: 1px solid $gray-100;
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  user-select: none;
}
.selected-item {
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.item:hover {
  background-color:  $secondary;
}

.drop-container {
  gap: 8px;
  border: 2px dashed $gray-300;
  background-color: $gray-200;
}

.drop-header {
  font-weight: bold;
  margin-bottom: 8px;
}
