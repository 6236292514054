// footer
.footer-container {
  padding-top: 20rem;
}

.footer-legal-section {
  background: linear-gradient(180deg, #d4eff0, #a7d1d4);
}

.footer-md-legal-section {
  background: linear-gradient(180deg, #e0f3f2, #a7d1d4);
}
.legal-notes-container {
  top: 260px;
  width: 93%;
}

.cloud-bottom {
  bottom: 0;
}

.onyxtec-logo {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
